import { selectors as userSelectors } from 'fe-common-app/lib/user'
import { getStore } from '../ddWindow'
import { ROUTES } from './helpers'

export enum Roles {
  FLEET_MANAGER = 'fleet-manager',
  FLEET_CARE_ADMIN = 'fleet-care-admin',
  SERVICE_ADVISOR = 'service-advisor',
  BI_REPORT = 'bi-report',
  BI_PILOT = 'bi-pilot',
  BI_PILOT_2 = 'bi-pilot-2',
  ASSISTANCE_OPERATOR = 'assistance-operator',
  TECHNICIAN = 'technician',
  DIGITAL_DEALER_USER = 'digitaldealer-user'
}

export const AUTHORIZED_NON_ORG_ROLES = [
  Roles.FLEET_CARE_ADMIN,
  Roles.BI_REPORT,
  Roles.BI_PILOT,
  Roles.BI_PILOT_2,
  Roles.ASSISTANCE_OPERATOR
]

const getRoles = (): string[] => {
  const { tokenParsed } = window.keycloak
  return tokenParsed.resource_access && tokenParsed.resource_access['dd-clients']
    ? tokenParsed.resource_access['dd-clients'].roles
    : []
}

export const hasRole = (role: string): boolean => getRoles().includes(role)

export const getActiveOrganization = (): string => {
  const state = getStore().getState()
  return userSelectors.activeOrganization(state.user)
}

export const hasActiveOrganization = (): boolean => !!getActiveOrganization()
const hasAnyRole = (): boolean => getRoles().length > 0
const hasAnyAuthorizedRoleInToken = (authorizedRoles: Roles[]): boolean => authorizedRoles.some(hasRole)
const isFleetCareAdmin = (): boolean => hasAnyAuthorizedRoleInToken([Roles.FLEET_CARE_ADMIN])

const hasAnyNonOrgRole = (): boolean => hasAnyAuthorizedRoleInToken(AUTHORIZED_NON_ORG_ROLES)

const isOnlyTechnician = () => {
  const rolesExcludingBaseRole = getRoles().filter((role) => role !== Roles.DIGITAL_DEALER_USER.toString())
  return rolesExcludingBaseRole.length === 1 && rolesExcludingBaseRole.includes(Roles.TECHNICIAN)
}

const getTargetPage = (): string => `/${window.location.pathname.split('/')[1]}`

export const isAuthorized = (): boolean => {
  switch (getTargetPage()) {
    case ROUTES.BI_REPORTS:
      return true
    case ROUTES.FLEET_CARE_ADMIN:
      return isFleetCareAdmin()
    case ROUTES.SELF_SERVICE:
      return true
    default:
      return (hasAnyRole() && hasActiveOrganization() && !isOnlyTechnician()) || hasAnyNonOrgRole()
  }
}
